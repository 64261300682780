@charset "utf-8";


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-style:normal;
	font-size: 100%;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

html{ overflow-y: scroll;}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

input, textarea,{
	margin: 0;
	padding: 0;
}

ol, ul{
    list-style:none;
}
p {
	margin: 0px;
	padding: 0px;
}


table{
    border-collapse: collapse;
    border-spacing:0;
}

caption, th{ text-align: left; }

a:focus {
	outline:none;
}

.clearfix:after {
	content: "."; 
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
}

.clearfix { min-height: 1px; }

* html .clearfix {
	height: 1px;
	/*¥*//*/
	height: auto;
	overflow: hidden;
	/**/
}

.both{ clear:both; }
.right { float: left; }

.inline_block {
	display: inline-block;
	*display: inline;
	*zoom: 1;
}




/*------------------オリジナル------------------*/



/*---------フォント系---------*/

/*フォントサイズを統一（1rem = 10px）*/
html{ font-size: 62.5%; }

body{-webkit-text-size-adjust: 100%;}

/*テキスト段落をジャスティファイ*/
p{ text-align: justify; }

/*テキストの指定*/
.txt10 { font-size:1.0rem; }
.txt12 { font-size:1.2rem; }
.txt13 { font-size:1.3rem; }
.txt14 { font-size:1.4rem; }
.txt15 { font-size:1.5rem; }
.txt16 { font-size:1.6rem; }
.txt18 { font-size:1.8rem; }
.txt20 { font-size:2.0rem; }
.txt22 { font-size:2.2rem; }
.txt24 { font-size:2.4rem; }
.txt26 { font-size:2.6rem; }
.txt28 { font-size:2.8rem; }
.txt30 { font-size:3.0rem; }

.txt-c { text-align:center; }
.txt-right { text-align: right!important; }
.txt-left { text-align: left!important; }
.txt-b{	font-weight: bold; }
.txt-half { font-size:70%; }
/*---------マージン系---------*/

/*イメージの下の隙間を埋める*/
img{ vertical-align: bottom; }
/*リンクのアンダーをなくす*/
a{ text-decoration: none; }


/*空き*/
.mt0  { margin-top:0rem!important; }
.mt10 { margin-top:1.0rem!important; }
.mt15 { margin-top:1.5rem!important; }
.mt20 { margin-top:2.0rem!important; }
.mt25 { margin-top:2.5rem!important; }
.mt30 { margin-top:3.0rem!important; }
.mt35 { margin-top:3.5rem!important; }
.mt40 { margin-top:4.0rem!important; }
.mt45 { margin-top:4.5rem!important; }
.mt50 { margin-top:5.0rem!important; }

.mr0  { margin-right:0rem!important; }
.mr10 { margin-right:1.0rem!important; }
.mr15 { margin-right:1.5rem!important; }
.mr20 { margin-right:2.0rem!important; }
.mr25 { margin-right:2.5rem!important; }
.mr30 { margin-right:3.0rem!important; }
.mr35 { margin-right:3.5rem!important; }
.mr40 { margin-right:4.0rem!important; }
.mr45 { margin-right:4.5rem!important; }
.mr50 { margin-right:5.0rem!important; }
.mr55 { margin-right:5.5rem!important; }
.mr60 { margin-right:6.0rem!important; }
.mr65 { margin-right:6.5rem!important; }
.mr70 { margin-right:7.0rem!important; }

.mb0  { margin-bottom:0rem!important; }
.mb10 { margin-bottom:1.0rem!important; }
.mb15 { margin-bottom:1.5rem!important; }
.mb20 { margin-bottom:2.0rem!important; }
.mb25 { margin-bottom:2.5rem!important; }
.mb30 { margin-bottom:3.0rem!important; }
.mb35 { margin-bottom:3.5rem!important; }
.mb40 { margin-bottom:4.0rem!important; }
.mb45 { margin-bottom:4.5rem!important; }
.mb50 { margin-bottom:5.0rem!important; }
.mb60 { margin-bottom:6.0rem!important; }
.mb70 { margin-bottom:7.0rem!important; }

.ml0  { margin-left:0rem!important; }
.ml10 { margin-left:1.0rem!important; }
.ml15 { margin-left:1.5rem!important; }
.ml20 { margin-left:2.0rem!important; }
.ml25 { margin-left:2.5rem!important; }
.ml30 { margin-left:3.0rem!important; }
.ml35 { margin-left:3.5rem!important; }
.ml40 { margin-left:4.0rem!important; }
.ml45 { margin-left:4.5rem!important; }
.ml50 { margin-left:5.0rem!important; }


.pt0  { padding-top:0rem!important; }
.pt10 { padding-top:1.0rem!important; }
.pt15 { padding-top:1.5rem!important; }
.pt20 { padding-top:2.0rem!important; }
.pt25 { padding-top:2.5rem!important; }
.pt30 { padding-top:3.0rem!important; }
.pt35 { padding-top:3.5rem!important; }
.pt40 { padding-top:4.0rem!important; }
.pt45 { padding-top:4.5rem!important; }
.pt50 { padding-top:5.0rem!important; }

.pb0  { padding-bottom:0rem!important; }
.pb10 { padding-bottom:1.0rem!important; }
.pb15 { padding-bottom:1.5rem!important; }
.pb20 { padding-bottom:2.0rem!important; }
.pb25 { padding-bottom:2.5rem!important; }
.pb30 { padding-bottom:3.0rem!important; }
.pb35 { padding-bottom:3.5rem!important; }
.pb40 { padding-bottom:4.0rem!important; }
.pb45 { padding-bottom:4.5rem!important; }
.pb50 { padding-bottom:5.0rem!important; }

.pl0  { padding-left:0rem!important; }
.pl10 { padding-left:1.0rem!important; }
.pl15 { padding-left:1.5rem!important; }
.pl20 { padding-left:2.0rem!important; }
.pl25 { padding-left:2.5rem!important; }
.pl30 { padding-left:3.0rem!important; }
.pl35 { padding-left:3.5rem!important; }
.pl40 { padding-left:4.0rem!important; }
.pl45 { padding-left:4.5rem!important; }
.pl50 { padding-left:5.0rem!important; }

.pr20 { padding-right: 2.0rem!important; }

.mt-10 { margin-top:-1.0rem!important; }
.mt-15 { margin-top:-1.5rem!important; }
.mt-20 { margin-top:-2.0rem!important; }
.mt-25 { margin-top:-2.5rem!important; }
.mt-30 { margin-top:-3.0rem!important; }
.mt-35 { margin-top:-3.5rem!important; }
.mt-40 { margin-top:-4.0rem!important; }
.mt-45 { margin-top:-4.5rem!important; }
.mt-50 { margin-top:-5.0rem!important; }

.mr-10 { margin-right:-1.0rem!important; }
.mr-15 { margin-right:-1.5rem!important; }
.mr-20 { margin-right:-2.0rem!important; }
.mr-25 { margin-right:-2.5rem!important; }
.mr-30 { margin-right:-3.0rem!important; }
.mr-35 { margin-right:-3.5rem!important; }
.mr-40 { margin-right:-4.0rem!important; }
.mr-45 { margin-right:-4.5rem!important; }
.mr-50 { margin-right:-5.0rem!important; }

.mb-10 { margin-bottom:-1.0rem!important; }
.mb-15 { margin-bottom:-1.5rem!important; }
.mb-20 { margin-bottom:-2.0rem!important; }
.mb-25 { margin-bottom:-2.5rem!important; }
.mb-30 { margin-bottom:-3.0rem!important; }
.mb-35 { margin-bottom:-3.5rem!important; }
.mb-40 { margin-bottom:-4.0rem!important; }
.mb-45 { margin-bottom:-4.5rem!important; }
.mb-50 { margin-bottom:-5.0rem!important; }

.ml-10 { margin-left:-1.0rem!important; }
.ml-15 { margin-left:-1.5rem!important; }
.ml-20 { margin-left:-2.0rem!important; }
.ml-25 { margin-left:-2.5rem!important; }
.ml-30 { margin-left:-3.0rem!important; }
.ml-35 { margin-left:-3.5rem!important; }
.ml-40 { margin-left:-4.0rem!important; }
.ml-45 { margin-left:-4.5rem!important; }
.ml-50 { margin-left:-5.0rem!important; }

.pt-10 { padding-top:-1.0rem!important; }
.pt-15 { padding-top:-1.5rem!important; }
.pt-20 { padding-top:-2.0rem!important; }
.pt-25 { padding-top:-2.5rem!important; }
.pt-30 { padding-top:-3.0rem!important; }
.pt-35 { padding-top:-3.5rem!important; }
.pt-40 { padding-top:-4.0rem!important; }
.pt-45 { padding-top:-4.5rem!important; }
.pt-50 { padding-top:-5.0rem!important; }

.cf{clear: fixed;}
.cb{clear: both;}
.fl{float: left;}
.fr{float: right;}

/*---------その他---------*/


/*ホバー時に指マークになるプロパティ*/
input[type="submit"], input[type="reset"], input[type="button"], button{ cursor: pointer; }

.center{ display: block; margin: 0 auto; }
.center-t{ text-align: center;}
.left{ float: left; }
.right{ float: right; }
.none{ display: none; }

.fixed {
  position: fixed;
  width: 100%;
  height: 100%;
}

/*ボタンタグのリセット*/
button{
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}


/*WordPressのフッターに出てくる余白を非表示*/
div#nendebcopy{
  display: none;
}


/* よく使うCSS start


a,a:hover {
	-webkit-transition: 0.7s;
	-moz-transition: 0.7s;
	-o-transition: 0.7s;
	transition: 0.7s;
}

a img:hover {
	filter: alpha(opacity=75);
	-moz-opacity:0.75;
	opacity:0.75;
}

よく使うCSS end  */



/*jquery.inview*/

.inviewfadeIn {
    opacity: 0;
    transition: .8s;
}
.fadeIn {
    opacity: 1.0;
}
/*----- 上へスライド -----*/
.inviewUp {
    transform: translate(0, 40px);
    -webkit-transform: translate(0, 40px);
    transition: .8s;
}
.Up {
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
}
/*----- 下へスライド -----*/
.inviewDown {
    transform: translate(0, -40px);
    -webkit-transform: translate(0, -40px);
    transition: .8s;
}
.Down {
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
}
/*----- ズームイン -----*/
.inviewzoomIn {
    transform: scale(0, 0);
    -webkit-transform: scale(0, 0);
    transition: .8s;
}
.zoomIn {
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
}
/*----- フェードインしながら上へスライド -----*/
.inviewfadeInUp {
    opacity: 0;
    transform: translate(0, 60px);
    -webkit-transform: translate(0, 60px);
    transition: .8s;
}
.fadeInUp {
    opacity: 1.0;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
}
/*----- フェードインしながら右へスライド -----*/
.inviewfadeInRight {
    opacity: 0;
    transform: translate(-20px, 0px);
    -webkit-transform: translate(-20px, 0px);
    transition: .8s;
}
.fadeInRight {
    opacity: 1.0;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
}
/*----- フェードインしながら左へスライド -----*/
.inviewfadeInLeft {
    opacity: 0;
    transform: translate(20px, 0px);
    -webkit-transform: translate(20px, 0px);
    transition: .8s;
}
.fadeInLeft {
    opacity: 1.0;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
}
/*----- フェードインしながら下へスライド -----*/
.inviewfadeInDown {
    opacity: 0;
    transform: translate(0, -50px);
    -webkit-transform: translate(0, -50px);
    transition: .8s;
}
.fadeInDown {
    opacity: 1.0;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
}